<template>
  <section class="max-md:pb-16">
    <!-- Form -->
    <div
      v-if="!user"
      class="mb-5 flex items-center justify-between max-md:flex-col">
      <SectionTitle
        title="Create Account"
        icon="heroicons:user-16-solid" />
      <div class="text-lg font-light text-gray-500">
        Have an account?
        <span
          class="cursor-pointer text-sky-900 underline"
          @click="showLoginModal = true">Sign in</span>
      </div>
    </div>

    <!-- Form -->
    <form
      class="flex flex-col gap-6"
      @submit.prevent="submit()">
      <!-- Existing User -->
      <div
        v-if="profile"
        class="flex items-center justify-between rounded-xl border border-gray-200 p-6">
        <div>
          <p class="text-sm text-gray-500">
            Signed in as
          </p>
          <div class="text-2xl font-medium">
            {{ profile.first_name }} {{ profile.last_name }}
          </div>
        </div>
        <button
          type="button"
          class="underline"
          @click="client.auth.signOut()">
          Logout
        </button>
      </div>

      <template v-if="!user">
        <!-- Customer Type -->
        <div class="flex w-fit divide-x rounded-lg border">
          <div class="flex items-center pl-4">
            <input
              id="customer_type_individual"
              v-model="form.customerType"
              type="radio"
              name="customer_type"
              value="individual"
              class="rounded-lg border border-zinc-200 shadow"
              required
              @click="clearCompanyDetails">
            <label
              for="customer_type_individual"
              class="py-3 pl-3 pr-4 text-zinc-500">Individual</label>
          </div>
          <div class="flex items-center pl-4">
            <input
              id="customer_type_company"
              v-model="form.customerType"
              type="radio"
              name="customer_type"
              value="company"
              class="rounded-lg border border-zinc-200 shadow"
              required>
            <label
              for="customer_type_company"
              class="py-3 pl-3 pr-4 text-zinc-500">Company</label>
          </div>
        </div>

        <!-- Company Details -->
        <div
          v-if="form.customerType === 'company'"
          class="flex flex-col gap-4">
          <div class="text-lg font-medium">
            Company Details
          </div>
          <!-- Company Name -->
          <div class="flex flex-col">
            <label
              for="company_name"
              class="mb-1.5 text-zinc-500">Company Name</label>
            <input
              id="company_name"
              v-model="form.companyName"
              type="text"
              placeholder="Enter company name"
              autocomplete="organization"
              class="flex-1 rounded-lg border border-zinc-200 px-4 py-3 shadow"
              required>
          </div>
          <!-- Company ABN -->
          <div class="flex flex-col">
            <label
              for="company_abn"
              class="mb-1.5 text-zinc-500">Company ABN</label>
            <input
              id="company_abn"
              v-model="form.companyABN"
              type="number"
              placeholder="Enter company ABN"
              autocomplete="off"
              class="flex-1 rounded-lg border border-zinc-200 px-4 py-3 shadow [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              required>
          </div>

          <!-- Company Phone -->
          <div class="flex flex-col">
            <label
              for="company_phone"
              class="mb-1.5 text-zinc-500">Company Phone</label>
            <input
              id="company_phone"
              v-model="form.companyPhone"
              type="tel"
              placeholder="Enter company phone"
              autocomplete="tel"
              class="flex-1 rounded-lg border border-zinc-200 px-4 py-3 shadow"
              required>
          </div>

          <!-- Company Address -->
          <div class="flex flex-col">
            <label
              for="company_abn"
              class="mb-1.5 text-zinc-500">Company Address</label>
            <div class="flex-1 rounded-lg border border-zinc-200 pl-1 shadow">
              <LocationSearchGoogle
                search-text="Search for address"
                @result="form.companyAddress = $event.address"
                @clear="form.companyAddress = null" />
            </div>
          </div>

          <div class="-mb-3 pt-2 text-lg font-medium">
            Primary Contact
          </div>
        </div>

        <!-- Email -->
        <div class="flex flex-col gap-3">
          <div class="flex flex-col">
            <label
              for="email"
              class="mb-1.5 text-zinc-500">Email Address</label>
            <div class="relative flex flex-1 items-center gap-2">
              <input
                id="email"
                type="email"
                placeholder="Enter email address"
                autocomplete="email"
                :value="form.email"
                class="flex-1 rounded-lg border border-zinc-200 px-4 py-3 shadow"
                :class="{ 'animate-pulse': isCheckingEmail === true }"
                required
                @input="debouncedCheckEmail">

              <Icon
                v-show="isCheckingEmail"
                name="svg-spinners:3-dots-scale"
                class="absolute right-4 h-5 w-1.5 text-zinc-500" />
            </div>

            <div
              v-if="emailInUse"
              class="mt-2 rounded-lg border border-sky-400 bg-sky-50 px-4 py-3 text-sky-900">
              Email address found.
              <span
                class="cursor-pointer text-sky-900 underline"
                @click="showLoginModal = true">Please sign in</span>
            </div>
          </div>

          <!-- Password -->
          <template v-if="!emailInUse">
            <div class="flex flex-col">
              <label
                for="password"
                class="mb-1.5 text-zinc-500">Password</label>
              <input
                id="password"
                v-model="form.password"
                type="password"
                placeholder="Enter Password"
                autocomplete="current-password"
                class="rounded-lg border border-zinc-200 px-4 py-3 shadow"
                required>
            </div>

            <!-- Name -->
            <div class="flex gap-4 max-md:flex-col">
              <div class="flex flex-1 flex-col">
                <label
                  for="first_name"
                  class="mb-1.5 text-zinc-500">First name</label>
                <input
                  id="first_name"
                  v-model="form.firstName"
                  type="text"
                  placeholder="Enter first name"
                  class="w-full rounded-lg border border-zinc-200 px-4 py-3 shadow"
                  required>
              </div>
              <div class="flex flex-1 flex-col">
                <label
                  for="last_name"
                  class="mb-1.5 text-zinc-500">Last name</label>
                <input
                  id="last_name"
                  v-model="form.lastName"
                  type="text"
                  placeholder="Enter last name"
                  class="w-full rounded-lg border border-zinc-200 px-4 py-3 shadow"
                  required>
              </div>
            </div>

            <!-- Number -->
            <div class="flex flex-col">
              <label
                for="phone"
                class="mb-1.5 text-zinc-500">Mobile Number</label>
              <input
                id="phone"
                v-model="form.phone"
                type="tel"
                placeholder="Enter mobile number"
                class="rounded-lg border border-zinc-200 px-4 py-3 shadow"
                required>
            </div>
          </template>
        </div>

        <p class="text-sm">
          By completing this order you agree to our
          <a
            href="/legal/termsandconditions"
            target="_blank"
            class="underline">Terms of Service</a>
          ,
          <a
            href="/legal/refunds"
            target="_blank"
            class="underline">Refunds Policy</a>
          and
          <a
            href="/legal/privacy"
            target="_blank"
            class="underline">Privacy Policy</a>
          .
        </p>

        <!-- Errors -->
        <Errors
          v-if="errors"
          :errors="errors" />
      </template>
      <Button
        id="confirm-booking"
        type="submit"
        class="w-full"
        size="xl"
        :disabled="isSubmitting"
        :class="{
          'animate-pulse': isSubmitting === true,
        }">
        Confirm Booking
      </Button>
    </form>
  </section>
</template>

<script setup lang="ts">
const client = useSupabaseClient<Database>()
const user = useSupabaseUser()
const { gtag } = useGtag()

const emit = defineEmits(['checkout'])

const props = defineProps({
  isSubmitting: {
    type: Boolean,
    default: false,
  },
})

const showLoginModal = useState('showLoginModal', () => false)

const form = ref({
  customerType: 'individual',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  phone: '',
  companyName: null,
  companyABN: null,
  companyAddress: null,
  companyPhone: null,
})

const errors = ref()

const { data: profile } = useAsyncData(`user-profile:${user.value?.id}`, async () => {
  if (!user.value) return null

  const { data, error } = await client.from('users').select('*, affiliate_ledger(value)').eq('id', user.value.id).single()
  if (error) {
    throw error
  }

  return data
})

function clearCompanyDetails() {
  form.value = {
    ...form.value,
    companyName: null,
    companyABN: null,
    companyPhone: null,
    companyAddress: null,
  }
}

async function submit() {
  // If the user is not logged in, sign them up
  if (!user.value) {
    if (form.value.password.length < 8) {
      errors.value = 'Password must be at least 8 characters.'
      return
    }

    if (!form.value.phone.match(/^04[0-9]{8}$/)) {
      errors.value = 'Phone number is invalid.'
      return
    }

    errors.value = null

    await signUp()

    try {
      gtag('event', 'sign_up', {
        method: 'email',
      })
    }
    catch (error) {
      console.error('Issue tracking sign_up', error)
    }
  }

  emit('checkout')
}

// Check Email
let debounceCheckEmail: NodeJS.Timeout | null = null
const isCheckingEmail = ref(false)
const emailInUse = ref()
watch(
  () => form.value.email,
  (value) => {
    emailInUse.value = undefined
  },
)

const checkEmail = async () => {
  isCheckingEmail.value = true

  const { data, error } = await useFetch('/api/check-email', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: form.value.email }),
  })

  isCheckingEmail.value = false

  if (error.value) {
    console.error(error)
    return
  }

  emailInUse.value = data.value.exists
}

const isValidEmail = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailPattern.test(email)
}

const debouncedCheckEmail = ($event: any) => {
  form.value.email = $event.target.value

  if (debounceCheckEmail) {
    clearTimeout(debounceCheckEmail)
  }

  if (!$event.target.value) {
    isCheckingEmail.value = false
    return
  }

  if (!isValidEmail($event.target.value)) {
    isCheckingEmail.value = false
    return
  }

  isCheckingEmail.value = true

  debounceCheckEmail = setTimeout(() => {
    checkEmail()
  }, 1000)
}

async function signUp() {
  const { data, error } = await useSupabaseClient().auth.signUp({
    email: form.value.email,
    password: form.value.password,
    options: {
      data: {
        first_name: form.value.firstName,
        last_name: form.value.lastName,
        phone: form.value.phone,
      },
    },
  })

  if (error || !data.user) {
    console.error('Issue signing up', { ...error })
    throw error
  }

  // Update User Profile
  try {
    const { error: updateProfileError } = await client
      .from('users')
      .update({
        customer: true,
        customer_type: form.value.customerType,
        company_name: form.value.companyName ?? null,
        company_abn: form.value.companyABN ?? null,
        company_address: form.value.companyAddress ?? null,
        company_phone: form.value.companyPhone ?? null,
      })
      .eq('id', data.user.id)

    if (updateProfileError) {
      console.error('Issue updating user profile', { ...updateProfileError })
      throw updateProfileError
    }
  }
  catch (error) {
    console.error('An error occurred while updating the user profile:', error)
  }

  return data
}
</script>
