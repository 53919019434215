<template>
  <label :for="id" class="relative inline-flex cursor-pointer items-center">
    <input :id="id" type="checkbox" :checked="model" class="peer sr-only" @change="model = !model" />
    <div
      class="peer-focus:ring-primary-200 h-8 w-14 rounded-full bg-gray-100 after:absolute after:left-1 after:top-1 after:h-6 after:w-6 after:rounded-full after:bg-white after:shadow after:transition-all after:content-[''] hover:bg-gray-200 peer-checked:bg-green-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-disabled:cursor-not-allowed peer-disabled:bg-gray-100 peer-disabled:after:bg-gray-50"></div>
  </label>
</template>

<script setup>
  const emit = defineEmits(['update:modelValue'])
  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    modelValue: { type: Boolean }
  })

  const model = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })
</script>
