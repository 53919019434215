<template>
  <div class="inline-flex items-center gap-2 rounded-full border border-black/5 bg-green-500 py-1.5 pl-4 pr-1.5 shadow-lg shadow-black/10 backdrop-blur-md">
    <div class="text-lg text-white">
      {{ label }}
    </div>
    <div class="flex items-center whitespace-nowrap rounded-full bg-green-600 px-3 py-1 text-white">
      {{ formatDurationLabels(time * 60, {'labels': 'short'}) }}
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    time: {
      type: Number,
      required: true
    },
    label: {
      type: String
    }
  })
</script>
