<template>
  <section>
    <SectionTitle title="Select your region" icon="heroicons:map-pin-solid" />

    <div class="mt-4 grid gap-3">
      <template
        v-for="region in regionConfig"
        :key="region.id">
        <Card
          class="h-20"
          :class="modelValue === region.id ? 'active' : 'inactive'"
          @click="emit('update:modelValue', region.id)">
          <div>
            <p class="text-xl font-semibold">{{ region.name }}</p>
          </div>
        </Card>
      </template>
    </div>
  </section>
</template>

<script setup lang="ts">
  const regionConfig = useState('regionConfig')
  const emit = defineEmits(['update:modelValue'])

  const props = defineProps({
    modelValue: {
      type: String
    }
  })
</script>
