<template>
  <section class="max-md:pb-12">
    <div class="flex flex-wrap items-center justify-between">
      <SectionTitle
        title="Excluded Items"
        icon="heroicons:clipboard-16-solid" />
      <slot />
    </div>

    <div class="mt-4 rounded-xl border bg-slate-50 p-2">
      <!-- Items -->
      <div class="mt-4 grid grid-cols-3 gap-6 p-3 md:grid-cols-4 md:p-6">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="flex flex-col items-center justify-center gap-2">
          <img
            :src="`/icons/${item.icon}.svg`"
            class="size-8">
          <p class="mt-1 text-center text-sm font-semibold">
            {{ item.name }}
          </p>
        </div>
      </div>

      <div class="p-3">
        <div class="flex flex-col items-center justify-between gap-2 rounded-xl border bg-slate-100 px-5 py-4">
          <div class="w-full">
            <p>
              <span class="grow font-medium">Heavy Items:</span>
              We are unable to take individual items over 120kg.
            </p>
          </div>
          <div class="w-full">
            <p>
              <span class="grow font-medium">Oversized Items:</span>
              Items no larger than 3m x 2m (W x H).
            </p>
          </div>
          <div
            v-if="service == 'removal'"
            class="w-full">
            <p>
              <span class="grow font-medium">Packing or Disassembly:</span>
              We are unable to provide packing or disassembly services. Items must be ready for collection.
            </p>
          </div>
        </div>
      </div>

      <!-- Toggle -->
      <div class="p-3">
        <div class="flex items-center justify-between rounded-xl border bg-white px-5 py-4">
          <p class="font-medium md:text-lg">
            No Excluded Items
          </p>
          <FormToggle
            id="dangerous-items"
            tabindex="0"
            :model-value="modelValue"
            @update:model-value="toggleExcludedItems($event)" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
  service: {
    type: String,
    required: true,
  },
})

const { gtag } = useGtag()

const toggleExcludedItems = (value) => {
  emit('update:modelValue', value)

  try {
    gtag('event', 'Completed Booking Form', {
      event_category: 'Booking Form',
      event_label: 'Excluded Items',
    })
  }
  catch (error) {
    console.error('Issue tracking select_item', { ...error })
  }
}

const items = [
  {
    name: 'Asbestos',
    icon: 'asbestos',
  },
  {
    name: 'Batteries',
    icon: 'batteries',
  },
  {
    name: 'Chemicals',
    icon: 'chemicals',
  },
  {
    name: 'Explosives',
    icon: 'explosives',
  },
  {
    name: 'Flammables',
    icon: 'flammable',
  },
  {
    name: 'Paint',
    icon: 'paint',
  },
  {
    name: 'Toxic Waste',
    icon: 'toxic-waste',
  },
  {
    name: 'Weapons',
    icon: 'weapons',
  },
  {
    name: 'Tyres',
    icon: 'tyres',
  },
  {
    name: 'Marble',
    icon: 'table',
  },
  {
    name: 'Pianos',
    icon: 'piano',
  },
  {
    name: 'Pool Tables',
    icon: 'billiard-ball',
  },
]
</script>
