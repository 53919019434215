export function scrollToAnchorPatched(id: string): void {
  const selector = `#${id}`
  const element = document.querySelector(selector)
  if (!element) {
    console.warn(`[MODS] Element with id '${id}' not found.`)
    return
  }

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}
