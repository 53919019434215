<template>
  <input
    ref="inputRef"
    type="text"
    class="h-12 w-full truncate border-none bg-transparent text-gray-950 focus:border-none focus:outline-none focus:ring-0"
    :placeholder="searchText"
    :value="input"
    @change="onTextInput"
    @input="onTextInput" />
  <span class="absolute -top-3 left-1/2 w-fit -translate-x-1/2 whitespace-nowrap rounded-full bg-red-500 px-2.5 text-white">{{ errorMessage }}</span>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { point, polygon, booleanPointInPolygon } from '@turf/turf'
  import { Loader } from '@googlemaps/js-api-loader'

  const config = useRuntimeConfig()
  const emit = defineEmits(['result', 'clear'])

  const props = defineProps({
    boundary: {
      type: Object,
      default: null
    },
    searchText: {
      type: String,
      default: 'Search'
    },
    input: {
      type: String,
      default: ''
    }
  })

  const inputRef = ref()
  const selectedPlace = ref()
  const errorMessage = ref('')

  // Variables
  let autocomplete: any | null
  let loader = new Loader({ apiKey: config.public.googlePlacesApiKey })

  // Functions
  function onTextInput() {
    errorMessage.value = ''
    if (selectedPlace.value) selectedPlace.value = null
    if (!inputRef.value.value) emit('clear')
  }

  function onPlaceChanged() {
    errorMessage.value = ''
    const place = autocomplete.getPlace()

    if (!place.geometry) {
      console.error("Autocomplete's returned place contains no geometry")
      return
    }

    const coordinates = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }

    if (props.boundary) {
      if (!isPointInMultiPolygon([coordinates.lng, coordinates.lat])) {
        inputRef.value.value = ''
        errorMessage.value = 'Oops, outside of the service area!'
        return
      }
    }

    selectedPlace.value = place
  }

  function isPointInMultiPolygon(coordinates: number[]): boolean {
    const turfPoint = point(coordinates)
    const turfMultiPolygon = polygon(props.boundary.coordinates)
    return booleanPointInPolygon(turfPoint, turfMultiPolygon)
  }

  // Watch
  watch(selectedPlace, (newValue) => {
    if (!newValue) {
      emit('clear')
    } else {
      emit('result', {
        id: newValue.place_id,
        name: newValue.name,
        address: newValue.formatted_address,
        coordinates: [newValue.geometry.location.lng(), newValue.geometry.location.lat()]
      })
    }
  })

  // Mounted
  onMounted(async () => {
    const { LatLngBounds, LatLng } = await loader.importLibrary('core')
    const { Autocomplete } = await loader.importLibrary('places')

    autocomplete = new Autocomplete(inputRef.value, {
      fields: ['geometry', 'name', 'place_id', 'formatted_address']
    })

    autocomplete.setComponentRestrictions({ country: ['au'] })

    if (props.boundary) {
      let points: any[] = []
      props.boundary.coordinates.forEach((group: any) => {
        group.forEach((coord: any[]) => {
          points.push(new LatLng(coord[1], coord[0]))
        })
      })

      const bounds = new LatLngBounds()

      points.forEach((point: any) => {
        bounds.extend(point)
      })

      autocomplete.setBounds(bounds)
    }

    autocomplete.addListener('place_changed', onPlaceChanged)
  })

  onUnmounted(() => {
    if (autocomplete && (window as any)?.google) {
      ;(window as any).google.maps.event.clearInstanceListeners(autocomplete)
    }
  })
</script>

<style>
  .pac-container {
    @apply rounded-lg;
  }
  .pac-item {
    @apply py-3 pl-3;
  }

  .pac-icon {
    @apply hidden;
  }
  .pac-item,
  .pac-item-query {
    @apply font-sans text-base;
  }

  .pac-logo:after {
    display: none !important;
  }
</style>
