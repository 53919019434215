<template>
  <div class="flex flex-wrap items-center gap-3">
    <div class="flex h-7 w-7 items-center justify-center rounded-lg bg-green-500 text-white">
      <Icon :name="icon" class="h-4 w-4" />
    </div>

    <h2 class="text-2xl">{{ title }}</h2>
  </div>
</template>

<script setup>
  const props = defineProps({
    icon: String,
    title: String
  })
</script>
