<template>
  <section>
    <SectionTitle
      title="Select your service"
      icon="heroicons:truck-solid" />
    <div class="mt-4 grid gap-3">
      <Card
        v-for="(service, index) in services.filter(service => service.status === 'published')"
        :id="service.id"
        :key="index"
        :class="modelValue === service.id ? 'active' : 'inactive'"
        @click="selectService(service.id)">
        <div>
          <p class="text-xl font-semibold">
            {{ service.name }}
          </p>
          <p class="mt-0.5 text-lg font-light text-gray-500">
            {{ service.description }}
          </p>
        </div>
      </Card>

      <div v-if="services.length == 0">
        <Card class="h-20" />
        <Card class="h-20" />
        <Card class="h-20" />
      </div>
    </div>
  </section>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])
const regionConfig = useState('regionConfig')

const { gtag } = useGtag()

const props = defineProps({
  modelValue: {
    type: String,
  },
  region: {
    type: String,
    required: true,
  },
})

const services = computed(() => {
  const region = regionConfig.value?.find(region => region.id === props.region)
  region?.services.sort((a, b) => a.sort - b.sort)

  return region?.services || []
})

function selectService(service) {
  emit('update:modelValue', service)

  try {
    gtag('event', 'select_item', {
      items: [{ item_id: service }],
    })
  }
  catch (error) {
    console.error('Issue tracking select_item', { ...error })
  }
}
</script>
